<template>
  <div class="wrapper d-flex align-center">
    <v-menu
      v-if="hasComment"
      :attach="attachEl"
      offset-y
      content-class="background-translucid"
      close-delay=50
      :z-index="displayZIndex"
      min-width="max-content"
      ref="menu"
      transition="slide-y-transition"
      origin="right top"
      :nudge-bottom="displayToTop ? 0 : 4"
      :nudge-top="displayToTop ? 4 : 0"
      :top="displayToTop"
      :left="displayToLeft"
      v-model="menu"
    >
      <template v-slot:activator="{ attrs }">
        <div
          class="d-flex align-end"
          v-bind="attrs"
          @mouseover="openMenu"
          @mouseleave="closeMenu"
        >
          <span class="text-no-wrap">{{commentPreview}}</span>
          <v-icon
            small
            v-if="!commentPreviewIsFull"
          >{{$icon('i.DotsHorizontal')}}</v-icon>
        </div>
      </template>
      <v-card
        class="background-plain ma-0 d-flex flex-column"
        ref="content"
        @mouseover="openMenu"
        @mouseleave="closeMenu"
        @click.native.stop
      >
        <resize-observer @resize="computePosition()">
          <v-card-text class="content-menu background-plain">
            {{text}}
          </v-card-text>
        </resize-observer>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import { CacheType } from '@/wasm/pkg'
import Column from '@/pages/search/controllers/column'
import DisplayableInContainer from '@/pages/search/mixins/displayable-in-container'
import DocumentEditor from '@/mixins/document-editor'

export default {
  inheritAttrs: false,
  mixins: [DisplayableInContainer, DocumentEditor],
  components: {
    ResizeObserver: () => import('@/components/resize-observer')
  },
  data () {
    return {
      menu: false,
      openMenuHandler: undefined,
      closeMenuHandler: undefined
    }
  },
  computed: {
    cacheType () {
      return CacheType?.LazyComment
    },
    hasComment () {
      return this.text.length > 0
    },
    id () {
      return this.value
    },
    commentColumnName () {
      return this.value?.commentColumnName
    },
    commentPreview () {
      return this.text.substr(0, 30)
    },
    commentPreviewIsFull () {
      return this.text.length <= 30
    },
    text () {
      return this.document?.text ?? ''
    }
  },
  methods: {
    cleanUpClose () {
      if (this.closeMenuHandler) {
        clearTimeout(this.closeMenuHandler)
        this.closeMenuHandler = undefined
      }
    },
    cleanUpOpen () {
      if (this.openMenuHandler) {
        clearTimeout(this.openMenuHandler)
        this.openMenuHandler = undefined
      }
    },
    openMenu () {
      this.cleanUpClose()
      this.openMenuHandler = setTimeout(() => {
        this.cleanUpOpen()
        this.menu = true
      }, 200)
    },
    closeMenu () {
      this.cleanUpOpen()
      this.closeMenuHandler = setTimeout(() => {
        this.cleanUpClose()
        this.menu = false
      }, 50)
    }
  },
  props: {
    value: Object,
    column: Column
  }
}
</script>
<style lang="stylus" scoped>
.wrapper
  position relative

.content-menu
  overflow-y true
  max-width 600px
  max-height 600px
</style>
