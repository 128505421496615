import { render, staticRenderFns } from "./topic-infos.vue?vue&type=template&id=b7059296&scoped=true&"
import script from "./topic-infos.vue?vue&type=script&lang=js&"
export * from "./topic-infos.vue?vue&type=script&lang=js&"
import style0 from "./topic-infos.vue?vue&type=style&index=0&id=b7059296&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b7059296",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VForm } from 'vuetify/lib/components/VForm';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
installComponents(component, {VBtn,VCard,VCardText,VCardTitle,VForm,VSelect,VSpacer,VTab,VTabItem,VTabs,VTabsItems})
