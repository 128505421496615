<template>
  <div class="d-flex gap flex-column flex-grow-1 related-accounts">
    <template v-if="isActive">
      <picker
        class="pt-2"
        :disabled="disabled"
        document-types="accounts"
        :label="$t('t.Accounts')"
        :excluded-items="linkedAccounts"
        @update:selected-document="$emit('link', $event)"
        reset-on-select
      />
      <cot-form
        class="overflow-y-auto flex-grow-1 pt-2"
        @input="$emit('is-valid', $event)"
        :disabled="disabled"
      >
        <template v-if="value.length">
          <div
            v-for="a in value"
            :key="a.accountId"
            class="d-flex gap flex-nowrap align-center"
            ref="linked-account"
          >
            <computed-document-ref
              class="text-truncate flex-grow-1"
              :id="a.accountId"
              document-type="accounts"
              :options="{ hideNavigation: false }"
            />
            <picker
              document-types="contact-groups"
              @update:selected-id="$emit('change-group', { accountId: a.accountId, contactGroupId: $event })"
              :selected-id="a.contactGroupId"
              :clearable="false"
              :rules="required"
              :label="$t('t.ContactGroup')"
              hide-message
            />
            <v-btn
              icon
              color="red"
              @click="$emit('unlink', a.accountId)"
            >
              <v-icon>{{$icon('i.Close')}}</v-icon>
            </v-btn>
          </div>
        </template>
        <template v-else>
          <div class="error--text subtitle-1">
            {{$t('t.ActiveContactMustBeAssignedToAtLeastOneAccount')}}
          </div>
        </template>
      </cot-form>
    </template>
    <template v-else>
      <span class="text-h6">{{$t('t.InactiveContactWarning')}}</span>
    </template>
  </div>
</template>

<script>
export default {
  components: {
    ComputedDocumentRef: () => import('@/components/documents-ref/computed-document-ref'),
    CotForm: () => import('@/components/cot-form'),
    Picker: () => import('@/components/picker')
  },
  data () {
    return {
      required: [v => !!v || this.$t('t.IsRequired')]
    }
  },
  computed: {
    linkedAccounts () {
      return this.value.map(r => ({ id: r.accountId }))
    }
  },
  watch: {
    async value (n, o) {
      if (!n.length || !o.length) { return }
      await this.$nextTick()
      const items = this.$refs['linked-account']
      if (!items) { return }
      items[items.length - 1].scrollIntoView({ behavior: 'smooth', block: 'end' })
    }
  },
  props: {
    disabled: {
      required: true,
      type: Boolean
    },
    fullPageDisplay: {
      required: true,
      type: Boolean
    },
    isActive: {
      required: true,
      type: Boolean
    },
    value: {
      required: true,
      type: Array
    }
  }
}
</script>

<style lang="stylus" scoped>
.related-accounts
  max-height 100%
</style>
