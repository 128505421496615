<template>
  <resize-observer
    class="overflow-y-hidden"
    style="width: 100%"
    @resize="evaluateDisplayMode"
  >
    <cache-presenter
      :cache="cache"
      :is-new="documentIsNew"
      class="d-flex flex-column fill-height flex-grow-1"
    >
      <document-toolbar
        v-on="$listeners"
        :tab="tab"
        :save="saveDocument"
        :revert="refreshDocument"
        :special-actions="accountContactActions"
        :show-delete="userIsMasterAdmin && !cacheDocIsActive"
        :delete-document="deleteDocument"
        :labels.sync="document.labels"
        :show-labels="!documentIsNew"
      />
      <document-in-tab>
        <cot-form
          ref="form"
          class="d-flex gap max-height overflow-x-hidden mx-n4 px-4 pb-4"
          :class="{'flex-column': !fullPageDisplay}"
        >
          <template v-if="fullPageDisplay">
            <v-card class="flex-grow-1 fit-content">
              <v-card-title>
                {{$t('t.Contact')}}
                <v-spacer />
                <div
                  class="d-flex justify-end"
                  v-if="!documentIsNew"
                >
                  <cgu-status
                    v-if="dataForm.isAllowPortalAccess"
                    :accepted-at="document.cguAcceptedAt"
                    :text="cguCalendarLinkText"
                  />
                  <v-chip
                    class="ml-2"
                    v-if="document.isLocked"
                    color="error"
                  >
                    <v-icon class="mr-2">{{$icon('i.Lock')}}</v-icon>
                    <span>{{$t('t.IsLocked')}}</span>
                  </v-chip>
                </div>
              </v-card-title>
              <v-card-text class="d-flex flex-column gap">
                <account-contact-infos-form
                  @input="validateMainForm"
                  class="flex-grow-1"
                  :disabled="!canEdit"
                  :document-is-new="documentIsNew"
                  :full-page-display="fullPageDisplay"
                  :messaging-cultures="messagingCultures"
                  :must-select-data-source="mustSelectDataSource"
                  v-model="computedForm"
                />
              </v-card-text>
            </v-card>
            <v-card
              v-show="fullPageDisplay"
              class="d-flex flex-column min-width-30"
            >
              <v-card-title v-if="!dataForm.isActive">
                {{$t('t.InactiveContactWarning')}}
              </v-card-title>
              <template v-else>
                <v-card-title>
                  {{$t('t.RelatedToAccounts')}}
                </v-card-title>
                <v-card-text class="d-flex gap flex-column overflow-y-hidden flex-grow-1">
                  <account-contact-infos-related-accounts
                    @change-group="changeGroup"
                    @link="link"
                    @unlink="unlink"
                    :disabled="!canEdit"
                    :full-page-display="fullPageDisplay"
                    :is-active="dataForm.isActive"
                    :value="computedRelatedAccounts"
                  />
                </v-card-text>
              </template>
            </v-card>
          </template>
          <template v-else>
            <v-tabs
              background-color="transparent"
              centered
              class="mb-3 tabs"
              color="primary"
              grow
              slider-color="primary"
              v-model="tabIndex"
            >
              <v-tab>
                <v-badge
                  color="error"
                  dark
                  :icon="$icon('i.ExclamationThick')"
                  :value="!isContactFormValid"
                >
                  {{$t('t.Contact')}}
                </v-badge>
              </v-tab>
              <v-tab>
                <v-badge
                  color="error"
                  dark
                  :icon="$icon('i.ExclamationThick')"
                  :value="!isRelationsFormValid || (dataForm.isActive && computedRelatedAccounts.length === 0)"
                >
                  {{$t('t.RelatedToAccounts')}}
                </v-badge>
              </v-tab>
            </v-tabs>
            <v-tabs-items
              class="tabs-wrapper"
              v-model="tabIndex"
            >
              <v-tab-item class="overflow-y-auto background-translucid">
                <account-contact-infos-form
                  class="overflow-x-hidden mr-4"
                  @input="validateMainForm"
                  @is-valid="isContactFormValid = $event"
                  :cgu-calendar-link-text="cguCalendarLinkText"
                  :disabled="!canEdit"
                  :document-is-new="documentIsNew"
                  :full-page-display="fullPageDisplay"
                  :messaging-cultures="messagingCultures"
                  :must-select-data-source="mustSelectDataSource"
                  v-model="computedForm"
                />
              </v-tab-item>
              <v-tab-item class="overflow-y-hidden flex-grow-1 flex background-translucid">
                <account-contact-infos-related-accounts
                  class="overflow-x-hidden mr-4"
                  @change-group="changeGroup"
                  @is-valid="isRelationsFormValid = $event"
                  @link="link"
                  @unlink="unlink"
                  :disabled="!canEdit"
                  :full-page-display="fullPageDisplay"
                  :is-active="dataForm.isActive"
                  :value="computedRelatedAccounts"
                />
              </v-tab-item>
            </v-tabs-items>
          </template>
        </cot-form>
      </document-in-tab>
      <v-dialog
        v-model="showPasswordPopUp"
        max-width=290
        @click:outside="showPasswordPopUp = false"
      >
        <v-card class="pa-0 ma-0">
          <v-card-title>
            <v-icon class="mr-2">{{$icon('i.ChangePassword')}}</v-icon>
            <span>{{$t('t.ChangePassword')}}</span>
          </v-card-title>
          <v-card-text>
            <v-form
              ref="changePasswordForm"
              v-model="changePasswordForm"
            >
              <v-text-field
                v-if="isCurrentUser"
                v-model="currentPassword"
                :label="$t('t.CurrentPassword')"
                :type="showCurrentPassword ? 'text' : 'password'"
              >
                <template v-slot:append>
                  <v-btn
                    icon
                    @click="showCurrentPassword = !showCurrentPassword"
                  >
                    <v-icon>{{showCurrentPassword ? $icon('i.Eye') : $icon('i.EyeOff')}} </v-icon>
                  </v-btn>
                </template>
              </v-text-field>
              <v-text-field
                v-model="newPassword"
                :label="$t('t.NewPassword')"
                :type="showNewPassword ? 'text' : 'password'"
                :rules="ruleNew"
                :hint="authenticationRulesDescription"
                persistent-hint
                @input="$refs.changePasswordForm.validate()"
              >
                <template v-slot:append>
                  <v-btn
                    icon
                    @click="showNewPassword = !showNewPassword"
                  >
                    <v-icon>{{showNewPassword ? $icon('i.Eye') : $icon('i.EyeOff')}} </v-icon>
                  </v-btn>
                </template>
              </v-text-field>
              <v-text-field
                v-model="confirmPassword"
                :label="$t('t.ConfirmPassword')"
                :type="showConfirmPassword ? 'text' : 'password'"
                :rules="ruleConfirmation"
              >
                <template v-slot:append>
                  <v-btn
                    icon
                    @click="showConfirmPassword = !showConfirmPassword"
                  >
                    <v-icon>{{showConfirmPassword ? $icon('i.Eye') : $icon('i.EyeOff')}} </v-icon>
                  </v-btn>
                </template>
              </v-text-field>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              @click="changePassword()"
              :disabled="!newPassword.length || !changePasswordForm"
            >{{$t('t.Save')}}</v-btn>
            <v-btn
              color="primary"
              @click="showPasswordPopUp = false"
            >{{$t('t.Cancel')}}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="showConfirmAddRelatedAccounts"
        max-width=500
      >
        <v-card>
          <v-card-title>{{$t('t.EmailAlreadyUsed')}}</v-card-title>
          <v-card-text>
            {{$t('t.ConfirmationMessageForEmailAlreadyUsed')}}
          </v-card-text>
          <v-card-text class="d-flex align-center">
            <div>{{$t('t.InfoSupForEmailAlreadyUsed')}}</div>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn @click="addRelatedAccounts()">
              {{$t('t.Yes')}}
            </v-btn>
            <v-btn
              color="primary"
              @click="showConfirmAddRelatedAccounts = false"
            >{{$t('t.No')}}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </cache-presenter>
  </resize-observer>
</template>

<script>

import DocumentEditor from '@/mixins/document-editor'
import Search, { GlobalSearch } from '@/pages/search/controllers'
import sidePanelController from '@/sidePanelController'
import { getDoc } from '@/wasm/pkg'

export default {
  mixins: [DocumentEditor],
  components: {
    AccountContactInfosForm: () => import('@/pages/account-contacts/account-contact-infos-form'),
    AccountContactInfosRelatedAccounts: () => import('@/pages/account-contacts/account-contact-infos-related-accounts'),
    CachePresenter: () => import('@/components/cache-presenter'),
    CguStatus: () => import('@/components/cgu-status'),
    CotForm: () => import('@/components/cot-form'),
    DocumentInTab: () => import('@/components/document-in-tab'),
    DocumentToolbar: () => import('@/components/document-toolbar'),
    ResizeObserver: () => import('@/components/resize-observer')
  },
  async created () {
    Object.assign(this.tab, { cancel: this.cancel })
  },
  async mounted () {
    this.loadCultures()
  },
  computed: {
    accountContactActions () {
      if (this.documentIsNew) { return undefined }

      const actions = []
      if (this.canEdit) {
        actions.push({
          icon: this.$icon('i.ChangePassword'),
          text: this.$t('t.ChangePassword'),
          callback: this.openChangePasswordPopUp
        })
      }

      if (this.computedIsLocked && this.canEdit) {
        actions.push({
          icon: this.$icon('i.Unlock'),
          text: this.$t('t.Unlock'),
          callback: this.unlock
        })
      }

      if (this.fullPageDisplay) {
        actions.push({
          icon: this.$icon('i.OpenInSidePanel'),
          text: this.$t('t.OpenInSidePanel'),
          callback: this.openInSidePanel
        })
      }

      return actions.length ? actions : undefined
    },
    actionItem () {
      return {
        id: this.id,
        type: 'account-contacts'
      }
    },
    authenticationRulesDescription () {
      if (this.authenticationRules?.pattern) {
        return this.authenticationRules.patternTranslatedValues.find(t => t.culture === this.$store.getters.currentUser.culture).description
      }
      return null
    },
    cacheDocIsActive () {
      return this.cache?.doc?.isActive
    },
    cacheType () {
      return DocumentEditor.CacheType.UserDetail
    },
    canEdit () {
      return this.$store.getters.currentUserHasPermission('AccountContacts') || this.userIsMasterAdmin
    },
    cguCalendarLinkText () {
      const dateForDiff = this.moment(this.document.cguAcceptedAt)
      const days = Math.abs(this.moment().startOf('day').diff(dateForDiff.startOf('day'), 'days'))

      return days > 1 ? this.$t('t.At').toLowerCase() : null
    },
    computedAvailabilityHours: {
      get () {
        return this.dataAvailabilityHours
      },
      set (v) {
        this.dataAvailabilityHours = v
        this.dataDocumentEmit()
      }
    },
    computedForm: {
      get () {
        return this.dataForm
      },
      set (v) {
        this.dataForm = Object.assign({}, this.dataForm, v)
        this.dataDocumentEmit()
      }
    },
    computedRelatedAccounts: {
      get () {
        return this.dataRelatedAccounts
      },
      set (v) {
        this.dataRelatedAccounts = v
        this.dataDocumentEmit()
      }
    },
    id () {
      return this.tab.documentId
    },
    isCurrentUser () {
      return this.$store.getters.currentUser.id === this.id
    },
    linkedAccounts () {
      return this.computedRelatedAccounts.map(r => ({ id: r.accountId }))
    },
    login () {
      return this.cache.doc.email
    },
    ruleConfirmation () {
      return [v => !this.newPassword.length || this.newPassword === v || this.$t('t.ConfirmationPasswordMismatch')]
    },
    ruleNew () {
      const rule = []
      if (this.authenticationRules?.pattern) {
        const regexp = new RegExp(this.authenticationRules.pattern)
        rule.push(v => regexp.test(v) || this.authenticationRulesDescription)
      }
      return rule
    },
    userIsMasterAdmin () {
      return this.$store.getters.isMasterAdmin()
    }
  },
  data () {
    return {
      authenticationRules: null,
      changePasswordForm: null,
      confirmPassword: '',
      contactForm: false,
      currentPassword: '',
      dataDocumentEmit: this.$nextTickDedup(this.emitDocument),
      dataForm: {},
      dataRelatedAccounts: [],
      defaultCulture: null,
      fullPageDisplay: true,
      isRelationsFormValid: true,
      isContactFormValid: true,
      messagingCultures: [],
      mustSelectDataSource: false,
      newPassword: '',
      openPanel: undefined,
      relatedAccountContactId: null,
      showConfirmAddRelatedAccounts: false,
      showConfirmPassword: false,
      showNewPassword: false,
      showPasswordPopUp: false,
      tabIndex: 0
    }
  },
  inject: {
    isInSidePanel: { default () { return false } }
  },
  methods: {
    changeGroup ({ accountId, contactGroupId }) {
      const account = this.computedRelatedAccounts.find(a => a.accountId === accountId)
      if (account) {
        account.contactGroupId = contactGroupId
        this.dataDocumentEmit()
        this.validateMainForm()
      }
    },
    emitDocument () {
      const doc = {
        id: this.tab.documentId,
        address1: this.dataForm.address1,
        address2: this.dataForm.address2,
        address3: this.dataForm.address3,
        availabilityHours: this.dataForm.availabilityHours,
        city: this.dataForm.city,
        country: this.dataForm.country,
        culture: this.dataForm.culture,
        email: this.dataForm.email,
        fax: this.dataForm.fax,
        fullname: this.dataForm.fullname,
        importDataSourceId: this.dataForm.importDataSourceId,
        importKey: this.dataForm.importKey,
        isActive: this.dataForm.isActive,
        isAllowPortalAccess: this.dataForm.isAllowPortalAccess,
        isAnonymous: this.dataForm.isAnonymous,
        isImportOverwritable: this.dataForm.isImportOverwritable,
        isLocked: this.dataForm.isLocked,
        jobTitle: this.dataForm.jobTitle,
        phone1: this.dataForm.phone1,
        phone2: this.dataForm.phone2,
        postalCode: this.dataForm.postalCode,
        relatedAccounts: this.dataRelatedAccounts,
        stateProvince: this.dataForm.stateProvince,
        title: this.dataForm.title
      }

      if (!this.lodash.isEqual(this.document, doc)) {
        this.document = doc
        this.$emit('update:document', this.lodash.cloneDeep(doc))
      }
    },
    link (account) {
      if (account) {
        this.computedRelatedAccounts.push({ accountId: account.id, contactGroupId: account.contactGroupId })
        this.$nextTick(this.validateMainForm)
      }
    },
    unlink (accountId) {
      const index = this.computedRelatedAccounts.findIndex(i => i.accountId === accountId)
      this.computedRelatedAccounts.splice(index, 1)
      this.$nextTick(this.validateMainForm)
    },
    evaluateDisplayMode (rect) {
      this.fullPageDisplay = rect.width > 1300
    },
    async loadCultures () {
      const {
        data: {
          cultures,
          defaultCulture
        }
      } = await this.$http().get('/core/v6/settings/cultures/messaging')

      this.messagingCultures = cultures.map(code => ({
        name: this.$t(`t.${code}`),
        code
      }))

      this.defaultCulture = defaultCulture
    },
    async deleteDocument () {
      Search.getActions(this.actionItem)
        .get('delete-account-contact')
        .executeQuick(
          this.actionItem,
          () => {
            if (this.isInSidePanel) {
              this.$emit('close-document')
              if (GlobalSearch.activeSearch?.action === 'grid') {
                GlobalSearch.activeSearch.refreshResults()
              }
            } else {
              this.$router.replace('/').catch(e => {
                if (e.name !== 'NavigationDuplicated') {
                  throw e
                }
              })
            }
          }
        )
    },
    async authenticateUserWithPassword (password, ignoreComplexity = false) {
      try {
        await this.$http().authenticate(this.login, password, !!window.localStorage.getItem('authentication'))
      } catch (e) {
        if (e.response && e.response.status === 401) {
          this.$store.dispatch('showErrorSnackbar', this.$t('t.InvalidUserNameOrPassword'))
        } else if (ignoreComplexity && e.response && e.response.status === 406) {
          // We reach here because the password complexity rules changed after the last password update and the current password does not respect it
          // But we don't care, because we are changing it
          return true
        } else {
          this.$store.dispatch('showErrorSnackbar', e.response.data?.message || this.$t('t.InternalError'))
        }
        return false
      }
      return true
    },
    async refreshDocumentHook () {
      const userNewSettings = await getDoc(DocumentEditor.CacheType.UserNew)
      this.mustSelectDataSource = userNewSettings.mustSelectDataSource

      if (this.documentIsNew) {
        this.setModule()

        this.document = Object.assign({}, this.document, {
          culture: this.defaultCulture,
          isAccountContact: true,
          isActive: true,
          relatedAccounts: (this.$route.name === 'new-contact-from-account' && this.tab.fromAccountId) ? [{ accountId: this.tab.fromAccountId }] : []
        })
      } else {
        // Prevent doing anything if the cache isn't loaded
        if (!this.document?.id) { return }

        this.$emit('document-title-change', { tab: this.tab, value: `${this.tab.typeName} ${this.computedFullname}` })
        this.setModule()
      }

      await this.$waitFor(() => this.$refs?.form)
        .then(() => setTimeout(() => {
          this.validateMainForm()
          this.$emit('document-ready', this.tab)
        }, 500))
        .catch(e => {
          // Do not throw if the cache is falsy, that mean the user as already close the document
          if (this.cache) {
            throw e
          }
        })
    },
    setModule () {
      if (this.isInSidePanel) { return }
      const moduleData = this.document?.id
        ? { componentName: 'user-header', data: this.document }
        : { name: this.$t('t.Contact'), icon: 'i.AccountContacts' }

      this.$store.commit('setModule', moduleData)
    },
    openChangePasswordPopUp () {
      this.$http().get(`core/authentication/rules?forUser=${this.id}`).then(r => { this.authenticationRules = r.data.regexPattern })
      this.showPasswordPopUp = true
    },
    openInSidePanel () {
      sidePanelController.displayDocument(this.document.id, 'account-contacts')
    },
    async changePassword () {
      // Validate user's current password and refresh session token
      if (this.isCurrentUser && !(await this.authenticateUserWithPassword(this.currentPassword, true))) {
        return
      }

      // Change user or contact password
      try {
        await this.$http().put('/core/authentication/password/update', { id: this.id, password: this.newPassword })
      } catch (e) {
        if (e.response && e.response.status === 401) {
          this.$store.dispatch('showErrorSnackbar', this.$t('t.InvalidUserNameOrPassword'))
        } else if (e.response && e.response.status === 406 && e.response.data?.message) {
          this.$store.dispatch('showErrorSnackbar', this.$t(e.response.data?.message))
        } else {
          this.$store.dispatch('showErrorSnackbar')
        }
        return
      }

      // Validate user's new password and get a new session token
      if (this.isCurrentUser && !(await this.authenticateUserWithPassword(this.newPassword))) {
        return
      }

      this.showPasswordPopUp = false

      this.$store.dispatch('showSuccessSnackbar', this.$t('t.Snackbar.SaveConfirmation'))
    },
    unlock () {
      this.$http().put(`/core/v6/users/${this.id}/unlock`)
    },
    async save () {
      const apiUrl = '/core/v6/account-contacts'

      if (this.documentIsNew) {
        if (this.document.email) {
          const url = '/core/v6/account-contacts/by-email'
          const response = await this.$http().post(url, { email: this.document.email })
          this.relatedAccountContactId = response?.data.id
        }

        if (this.relatedAccountContactId) {
          this.$nextTick(() => { this.showConfirmAddRelatedAccounts = true })
        }

        const response = await this.$http().post(apiUrl, this.document)
        this.$nextTick(() => {
          this.$router.replace('/account-contacts/' + response.data?.id)
        })
        return response
      } else {
        return await this.$http().put(`${apiUrl}/${this.id}`, this.document)
      }
    },
    validateMainForm () {
      if (!this.$refs.form) { return }
      const value = this.$refs.form?.validateAll() && (!this.dataForm.isActive || this.document.relatedAccounts.length > 0)
      this.$nextTick(() => this.$emit('document-can-save', { tab: this.tab, value }))
    },
    async addRelatedAccounts () {
      const apiUrl = `/core/v6/account-contacts/${this.relatedAccountContactId}/add-related-accounts`
      const response = await this.$http().put(apiUrl, { relatedAccounts: this.document.relatedAccounts || [] })
      this.$nextTick(() => {
        this.$router.replace('/account-contacts/' + response.data?.id)
      })
    },
    cancel () {
      this.$removeFromKeepAliveCache()
      this.$destroy()
    }
  },
  watch: {
    document: {
      immediate: true,
      handler (v) {
        if (!this.lodash.isEqual(this.dataRelatedAccounts, v?.relatedAccounts)) {
          this.dataRelatedAccounts = v?.relatedAccounts || []
        }
        this.dataForm = Object.assign({}, this.dataForm, {
          address1: v?.address1,
          address2: v?.address2,
          address3: v?.address3,
          availabilityHours: v?.availabilityHours,
          city: v?.city,
          country: v?.country,
          culture: v?.culture,
          email: v?.email,
          fax: v?.fax,
          fullname: v?.fullname,
          importDataSourceId: v?.importDataSourceId,
          importKey: v?.importKey,
          isActive: v?.isActive ?? true,
          isAllowPortalAccess: v?.isAllowPortalAccess,
          isAnonymous: v?.isAnonymous,
          isImportOverwritable: v?.isImportOverwritable,
          isLocked: v?.isLocked,
          jobTitle: v?.jobTitle,
          labels: v?.labels,
          phone1: v?.phone1,
          phone2: v?.phone2,
          postalCode: v?.postalCode,
          stateProvince: v?.stateProvince,
          title: v?.title
        })
      }
    },
    showPasswordPopUp: function (isShown) {
      if (!isShown) {
        this.currentPassword = ''
        this.newPassword = ''
        this.confirmPassword = ''
        this.showConfirmPassword = false
        this.showCurrentPassword = false
        this.showNewPassword = false
      }
    }
  },
  props: {
    tab: Object
  }
}
</script>

<style lang="stylus" scoped>
.fit-content
  height fit-content

.flex
  display flex

.max-height
  height 100%

.min-width-30
  min-width 30vw

.tabs-wrapper
  flex 100% 0 1
  height 100%
</style>
