<template>
  <v-menu
    :close-on-click="Boolean(!clickOutsideArgs.handler)"
    :close-on-content-click="false"
    v-click-outside="clickOutsideArgs"
    :attach="attach"
    content-class="menu"
    :left="left"
    max-height="75vh"
    min-width="fit-content"
    :nudge-bottom="nudgeBottom"
    offset-y
    :top="top"
    :z-index="zIndex"
    v-model="computedMenu"
    ref="menu"
  >
    <template v-slot:activator="{attrs, on, value}">
      <slot
        name="activator"
        :attrs="attrs"
        :on="on"
        :value="value"
      >
        <v-btn
          data-cy="activator"
          class="border-radius"
          :class="activatorClass"
          :color="color"
          small
          v-on="on"
        >
          <v-icon
            v-if="icon"
            left
          >
            {{icon}}
          </v-icon>
          {{activatorTitle}}
        </v-btn>
      </slot>
    </template>
    <resize-observer @resize="recomputePosition">
      <v-sheet
        :data-cy="dataCy"
        :class="contentClass"
        class="flex-grow-1 d-flex flex-column"
        @keydown.stop
      >
        <slot
          name="default"
          :value="value"
        />
      </v-sheet>
    </resize-observer>
  </v-menu>
</template>

<script>
export default {
  components: {
    ResizeObserver: () => import('@/components/resize-observer')
  },
  data () {
    return {
      menuIsOpen: false
    }
  },
  computed: {
    computedMenu: {
      get () {
        return this.menuIsOpen
      },
      async set (v) {
        if (this.menuIsOpen !== v) {
          this.$emit('input', v)
        }
        this.menuIsOpen = v
      }
    },
    recomputePosition () {
      return this.lodash.debounce(() => {
        this.$nextTick(this.$triggerResize)
      }, 50)
    }
  },
  watch: {
    value: {
      immediate: true,
      handler (v) {
        this.menuIsOpen = v
      }
    }
  },
  props: {
    activatorClass: String,
    activatorTitle: {
      default: 'menu-activator',
      type: String
    },
    attach: {
      type: [String, Boolean, Object, HTMLElement],
      default: undefined
    },
    clickOutsideArgs: {
      default: () => ({}),
      type: Object
    },
    color: String,
    contentClass: {
      default: 'py-4',
      type: String
    },
    dataCy: String,
    icon: String,
    left: Boolean,
    nudgeBottom: [Number, String],
    top: Boolean,
    value: Boolean,
    zIndex: {
      default: undefined,
      type: [Number, String]
    }
  }
}
</script>

<style lang="stylus" scoped>
.menu
  display flex
</style>
