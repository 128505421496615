// @ts-check

import _ from 'lodash'
import Vue from 'vue'
import VueInstance from '@/main'
import ItemList from './item-list'
import Column from './column'
import Scope from './scope'
import IncExc from './inc-exc'
import FilterParam from './filter-param'
import DocPin from './doc-pin'
import ActionManager from './action-manager'

import { MainPresetPart, scopeToKey } from '@/wasm/pkg'

import Debug, { debugMemoryCleanUp } from '@/debugger'

/**
 * @typedef {{id: (number|string), type: string}} DocumentId
 */

/**
 * @typedef {'delete'|'download-letters'|'end-escalation-protocols'|'execute-processes'|'export-csv'|'linking'|'grid'
 * |'grid-selected'|'pin'|'reorder'|'send-ar24-message'|'set-account-contact-groups'|'set-account-contact-properties'
 * |'set-dispute-properties'|'set-labels-link'|'set-user-properties'|'set-account-properties'|'set-reminder-status'|'set-invoice-properties'
 * |'set-collaboration-properties'|'set-promise-properties'|'set-allocation-announcement-properties'
 * |'set-topic-properties'|'set-work-items'|'suggest'|'sync-ar24-message'|'send-maileva-letter'|'sync-maileva-letter' } SearchAction
 * @readonly
 */

/**
 * @typedef {'contains'|'exact'} SearchMatchMode
 * @readonly
 */

/**
 * @typedef {'picker'|'global-search'} SearchSuggestMode
 * @readonly
 */

/**
 * @typedef {import('./action').default} Action
 * @typedef {import('./item-list').Item} Item
 * @typedef {import('./item-list').RawItem} RawItem
 * @typedef {import('./filter-param').Condition} Condition
 * @typedef {import('./filter-param').Operator} Operator
 * @typedef {import('./filter-param').ValueType} ConditionValueType
 */

let searchId = 0
export const MAX_SELECTED_ITEMS = 300
/**
 * @class Search manipulation object
 */
export default class Search {
  showPreviewPopup = false
  #action
  /** @type {boolean} */
  #allowEmptyParamsType
  /** @type {boolean} */
  #amtColsOnly
  #audit
  /** @type {string} */
  #autoFilterType
  /** @type {boolean} */
  #avgColsOnly
  /** @type {boolean} */
  #boolColsOnly
  #cameleonScope
  /** @type {boolean} */
  #canSendEmail
  /** @type {boolean} */
  #canSendLetterDematerialized
  /** @type {boolean} */
  #canSendMailevaLetter
  /** @type {boolean} */
  #canSendSms
  /** @type {string} */
  #category
  /** @type {Object.<DocumentId['type'], Array.<DocumentId['type']>>} */
  #children = {}
  /** @type {boolean} */
  #cmpColsOnly
  /** @type {boolean} */
  #dateColsOnly
  #debug
  /** @type {Vue} */
  #eventBus
  #filters
  /** @type {Search} */
  #gridSelectedSearch
  #groupContributorTypes = new IncExc()
  /** @type {boolean} */
  #hasEmail
  /** @type {boolean} */
  #ignoreTextMainSearch = false

  #lastSearch = {
    documentType: undefined,
    filters: undefined,
    searchText: '',
    scope: undefined
  }

  #linkMode = {
    grantUsersOf: undefined,
    link: true,
    /** @type {DocumentId} */
    target: undefined
  }

  /** @type {DocumentId} */
  #linkTarget
  #lock = Promise.resolve()
  /** @type {Search} */
  #mainSearch
  #matchMode = Search.matchMode.DISTANCE
  /** @type {boolean} */
  #logRun
  #nextPageQueue = Promise.resolve()
  /** @type {boolean} */
  #noColsAging
  /** @type {boolean} */
  #noColsRelation
  #pageSize = 20
  #reactiveData = Vue.observable({
    allItemsSelected: false,
    bulkActions: [],
    visiblePins: undefined,
    canSelectItems: true,
    /** @type {Array.<Column>} */
    columns: [],
    counts: {},
    /** @type {FilterParam} */
    filterSet: undefined,
    internalColumnSet: undefined,
    internalCustomColumnSetId: undefined,
    internalCustomSortSetId: undefined,
    internalSortSet: undefined,
    keepSelectionAfterAction: false,
    showSelectedItems: false,
    /** @type {Array.<Item>} */
    items: [],
    mainPreset: undefined,
    page: 0,
    pageCount: 0,
    /** @type {DocPin} */
    pins: undefined,
    /** @type {DocumentId} */
    previewDocument: undefined,
    querying: false,
    /** @type {Scope} */
    scope: undefined,
    searchText: '',
    /** @type {string} */
    selectedDocumentType: undefined,
    /** @type {IncExc<Item>} */
    selectedItems: new IncExc()
      .setEqualCustomizer((a, b) => _.isEqual(a.id, b.id) && a.type === b.type),
    sorts: {
      sortBy: [],
      sortDesc: []
    }
  })

  #refreshAllCounts = false
  #requestManager

  #searchedDocumentTypes = new IncExc()
  #searchedIds = new IncExc()
  #searchId = searchId++
  #searchOnType = true
  #selectionModeExclusive = false
  /** @type {boolean} */
  #sumColsOnly
  /** @type {SearchSuggestMode} */
  #suggestMode
  /** @type {string} */
  #version
  /** @type {Function} */
  #unwatchMainPreset
  #useInfiniteScroll = false
  /** @type {boolean} */
  #workItemTypeIsAccountAction
  /** @type {MainPresetPart} */
  #mainPresetPart

  #actionManager

  /**
   * @param {SearchAction} [action=Search.actions.SUGGEST]
   */
  constructor(action = Search.actions.SUGGEST) {
    debugMemoryCleanUp(this, `search ${this.searchId}`)
    this.#debug = Debug(`cot:search:${this.searchId}`)
    this.#debug('New search created with action "%s" and id %d', action, this.#searchId)
    if (action === Search.actions.GRID) {
      this.#actionManager = new ActionManager(this, true)
    }
    this.#action = action
    this.#requestManager = {
      /** @type {AbortController} */
      controller: undefined,
      http: VueInstance.$http().debounced(action === Search.actions.GRID ? 400 : 200)
    }
  }

  /** Helpers methods */

  /**
   * Build mainPresetPart
   */
  _buildMainPresetPart () {
    this.#mainPresetPart = new MainPresetPart()
    this.#mainPresetPart.active = true
    this._setMainPresetPartScopeKey()
  }

  /**
   * Return main search selected ids as selected document ids for current search
   * @private
   * @returns {IncExc}
   */
  _getIdsFromMainSearch () {
    return this.#mainSearch.selectedItems
  }

  /**
   * Create an event bus if needed then return the event bus
   * @private
   * @returns {Vue}
   */
  _getOrCreateEventBus () {
    return (this.#eventBus ??= new Vue())
  }

  /**
   * Create a  if needed then return the grid selected search
   * @private
   * @returns {Search}
   */
  _getOrCreateGridSelectSearch () {
    return (this.#gridSelectedSearch ??= new Search(Search.actions.GRID_SELECTED).setMainSearch(this))
  }

  /**
   * Return an object representing the actual presets used
   * @param {Search} [targetSearch = this] A search used as reference
   * @returns {{columns: any, filters: any, sorts:any}}
   */
  _getPresets (targetSearch = this) {
    // Clone the preset to be sent so it can be altered without breaking the actual inenr preset
    const presets = _.cloneDeep(
      // Never use defaultsDeep here or columnSet and columns will coexist in the same search request, resulting in an error
      _.defaults(
        {},
        {
          columns: targetSearch.internalColumnSet,
          sorts: targetSearch.internalSortSet
        },
        targetSearch.mainPreset?.docs?.[targetSearch.documentType]
      )
    )

    // Remove conds with empty list of values
    if (presets.filters?.conds) {
      presets.filters.conds = presets.filters.conds
        .filter(cond => cond.comparand.c.length)
    }

    return presets
  }

  /**
   * Return true if the search must return the first page
   * @private
   * @returns {boolean}
   */
  _mustQueryFirstPage () {
    return this.documentType !== this.#lastSearch.documentType ||
      !_.isEqual(this.filters, this.#lastSearch.filters) ||
      this.searchText !== this.#lastSearch.searchText ||
      !_.isEqual(this.scope, this.#lastSearch.scope) ||
      this.#reactiveData.page === 0
  }

  /**
   * Execute a search action and handle it's result accordingly to the action type
   * @private
   * @param {Object} overrideJSON An object to send instead of the serialized instance of this
   * @param {Object} [axiosConfig] An object containing axios config
   * @returns {Promise<*>}
   */
  async _query (overrideJSON, axiosConfig) {
    // Shortcut to prevent search execution while not logged in
    if (!VueInstance.$http().hasToken) { return }
    await this.#lock
    if (!this.#reactiveData.querying) {
      this.#reactiveData.querying = true
      this.#eventBus?.$emit('querying')
    }
    this.#requestManager.controller?.abort()
    this.#requestManager.controller = new AbortController()

    try {
      const isPinOrSuggest = [Search.actions.PIN, Search.actions.SUGGEST].includes(this.action)
      const { data } = await this.#requestManager.http.post(
        '/core/v6/search',
        overrideJSON ?? this,
        Object.assign({ signal: this.#requestManager.controller.signal }, axiosConfig)
      )
      this.#debug('Query executed')
      if (this.allItemsSelected && this.searchText !== this.#lastSearch.searchText) {
        this.selectAllItems(false)
      }
      if (this.action === Search.actions.GRID) {
        this.#reactiveData.visiblePins = data.pins

        this.#reactiveData.columns = data.cols.map((column, index) => new Column(column, data.documentType, index))

        this.sorts.sortBy = []
        this.sorts.sortDesc = []

        data.cols.map(c => c.sort ?? []).forEach(([index, desc], colIndex) => {
          if (index > -1) {
            this.sorts.sortBy[index] = 'c' + colIndex
            this.sorts.sortDesc[index] = desc === 'd'
          }
        })
        this.#children = data.children

        if (isFinite(data.counts)) {
          Vue.set(this.#reactiveData.counts, data.documentType, [data.counts, this.counts[data.documentType]?.[1]])
        } else {
          this.#reactiveData.counts = Vue.observable(data.counts)
        }

        if (!this.documentType) {
          this.setDocumentType(data.documentType)
        }

        if (this.mainPreset) {
          if (!this.filterSet) {
            this.setFilterSet(new FilterParam(this.mainPreset?.docs?.[this.documentType].filters), true)
          }
          if (!this.pins) {
            this.setPins(new DocPin(this.mainPreset?.docs?.[this.documentType].pins), true)
          }
        }

        const pageCount = Math.ceil(this.counts[data.documentType]?.[0] / this.#pageSize)
        this.#reactiveData.pageCount = isNaN(pageCount) ? 0 : pageCount
      }

      if (this._mustQueryFirstPage()) {
        this.#reactiveData.page = 0
      }

      if (
        (this.action === Search.actions.GRID && !this.#useInfiniteScroll) ||
        ((isPinOrSuggest || this.#useInfiniteScroll) && this._mustQueryFirstPage())
      ) {
        const items = new ItemList(data.items, this.canSelectItems ? this.#actionManager : undefined, true).toArray()
        this.selectedItems.refreshItems(items)
        this._updateBulkActions()
        this.#reactiveData.items = items
      }

      if (isPinOrSuggest || this.#useInfiniteScroll) {
        if (!this._mustQueryFirstPage()) {
          this.#reactiveData.items.push(...new ItemList(data.items, this.canSelectItems ? this.#actionManager : undefined, true).toArray())
        }
        if (isPinOrSuggest) {
          Vue.set(this.#reactiveData.counts, data.documentType, [data.count, undefined])
        }
      }

      this.#lastSearch = {
        documentType: this.documentType,
        filters: this.filters,
        searchText: this.searchText,
        scope: this.scope
      }
      this.#version = data.version
      this.#refreshAllCounts = false

      this.#reactiveData.querying = false
      this.#eventBus?.$emit('complete')

      return data
    } catch (e) {
      if (e !== 'Debounced' && e?.message !== 'canceled') {
        this.#reactiveData.querying = false
        this.#eventBus?.$emit('complete')
        throw e
      }
    }
  }

  /**
   * Set the scope key for the main preset part
   */
  async _setMainPresetPartScopeKey () {
    this.#mainPresetPart?.setKey({ search: scopeToKey(this.scope) })
  }

  /**
   * Update bulk action list based on the item selection
   */
  _updateBulkActions () {
    if (this.allItemsSelected) {
      this.#reactiveData.bulkActions = this.items[0]?.bulkActions.filter(a => !a.hasExcludeRules && !a.canOnlyBeQuick)
      return
    }

    if (!this.selectedItems?.isEmpty()) {
      const inclusionValidatedActions = _.uniq(
        this.selectedItems.includeItems
          .flatMap(i => i.bulkActions.filter(a => a.hasIncludeRules && a.validateRules(this.selectedItems.includeItems)))
      )

      const exclusionValidatedActions = _.intersection(...this.selectedItems.includeItems.map(item => item.bulkActions))
        .filter(a => !a.hasIncludeRules && a.validateRules(this.selectedItems.includeItems))

      const actions = [...inclusionValidatedActions, ...exclusionValidatedActions].filter(a => !a.canOnlyBeQuick)

      this.#reactiveData.bulkActions = actions
      return
    }

    this.#reactiveData.bulkActions = []
  }

  /**
   * Alter the current main preset while firing reactivity
   * @private
   * @param {'columns'|'sorts'|'filters'|'pins'} key
   * @param {Object.<string, any>} value
   */
  _updateMainPreset (key, value) {
    // Create a copy of the presetwith default chained properties
    const alteredPreset = _.defaultsDeep(
      {},
      this.mainPreset,
      { docs: { [this.documentType]: { [key]: {} } } }
    )

    // Apply the value into the copied preset
    alteredPreset.docs[this.documentType][key] = value

    // Apply the copied preset and fire reactivity
    Vue.set(this.mainPreset, 'docs', alteredPreset.docs)
  }

  /**
   * Watch the main preset.
   * This method should not be called directly but debounced by calling_watchMainPreset()
   * @private
   * @returns {Promise<void>}
   */
  async _watchMainPreset () {
    await this.#lock

    // Skip if the main preset is already watched
    if (this.#unwatchMainPreset) { return }
    this.#debug('Watching main preset change')

    this._buildMainPresetPart()

    // Lock the search during the main preset loading
    this.#lock = new Promise(resolve => {
      const handler = setInterval(() => {
        try {
          if (this.#mainPresetPart.obs.status === 200) {
            clearInterval(handler)
            this.#unwatchMainPreset = VueInstance.$watch(
              () => this.#mainPresetPart.obs?.doc,
              mainPreset => {
                // Bug 11798 - Compare the stringified versions of main preset because of pins.stateColumn.currency
                if (mainPreset && !_.isEqual(
                  JSON.parse(JSON.stringify(this.mainPreset ?? {})),
                  JSON.parse(JSON.stringify(mainPreset ?? {}))
                )) {
                  this.#debug('Main preset changed')

                  // Set columns and sorts sets without applying to main presets
                  if (mainPreset.docs[this.documentType]) {
                    this.#reactiveData.internalColumnSet = undefined
                    this.#reactiveData.internalSortSet = undefined
                    this.setColumnSet(mainPreset.docs[this.documentType].columns?.['column-set-id'], true)
                    this.setSortSet(mainPreset.docs[this.documentType].sorts?.['sort-set-id'], true)
                    this.setFilterSet(new FilterParam(mainPreset.docs[this.documentType].filters), true)
                    this.setPins(new DocPin(mainPreset.docs[this.documentType].pins), true)
                  }

                  this.selectAllItems(false)

                  // Execute the search using the main-preset
                  this.execute(true)
                }

                this.#reactiveData.mainPreset = mainPreset
              },
              { immediate: true } // This must be immediate or nothing that is main-preset related will work
            )

            resolve()
          }
        } catch (_) {
          if (!this.#mainPresetPart) {
            // In case the mainPresetPart has been destroyed during the waiting phase
            // by a user navigating too fast back and forth, build a new one
            this._buildMainPresetPart()
          } else {
            // This catch block is mainly here for dev purpose and prevent an
            // infinite loop of crashes during hot reloading
            clearInterval(handler)
            window.location.reload()
          }
        }
      }, 100)
    })
  }

  /**
   * Add filtering value for a column
   * @param {Condition} condition
   * @param {string} value
   * @returns {this}
   */
  addConditionValue (condition, value) {
    this.setFilterSet(this.filterSet.addConditionValue(condition, value))
    return this
  }

  /**
   * @callback SearchCallback
   * @param {this} search
   * @returns {void}
   */
  /**
   * @param {SearchCallback} fn
   * @returns {this}
   */
  chain (fn) {
    fn(this)
    return this
  }

  /**
   * Clear a column's filtering values
   * @param {Condition} condition
   * @returns {this}
   */
  clearCondition (condition) {
    this.setFilterSet(this.filterSet.clearCondition(condition))
    return this
  }

  /**
   * Clear the search result
   * @returns {this}
   */
  clearItems () {
    this.#reactiveData.items = []
    return this
  }

  /**
   * Clear the search text without firing a new query
   * @returns {this}
   */
  clearText () {
    this.#debug('Text has been cleared')
    this.#reactiveData.searchText = ''
    return this
  }

  /**
   * Disable item selection. Can be enabled by giving false as argument
   * @param {boolean} [disableSelection=true]
   */
  disableItemSelection (disableSelection = true) {
    this.#reactiveData.canSelectItems = !disableSelection
  }

  /**
   * Disable search execution on text change. Can be enabled by giving false as argument
   * @param {boolean} [disable  = true]
   * @returns {this}
   */
  disableSearchOnType (disable = true) {
    this.#searchOnType = !disable
    return this
  }

  /**
   * Execute a search
   * @param {boolean} [refreshAllCounts] In GRID action only, will ask the search to recompute the counts for every available document-types
   * @returns {Promise<*>}
   */
  async execute (refreshAllCounts = false) {
    this.#refreshAllCounts ||= refreshAllCounts
    const axiosConfig = this.action === Search.actions.EXPORT_CSV ? { responseType: 'blob' } : undefined
    return this._query(undefined, axiosConfig)
  }

  /**
   * Execute a search action
   * @param {Object} arbitraryJSON An object to merge into the search
   * @param {Object} [axiosConfig] An object containing axios config
   * @returns {Promise<*>}
   */
  async executeWithJSON (arbitraryJSON, axiosConfig) {
    this.#debug('Executing with given payload')
    return this._query(Object.assign(this.toJSON(), arbitraryJSON), axiosConfig)
  }

  /**
   * @param {DocumentId['type']} document
   * @returns {Array.<DocumentId['type']>}
   */
  getDocumentChildren (document) {
    return (this.#children[document] ?? []).map(type => ({ count: this.counts[type]?.[0], name: this.counts[type]?.[1], type }))
  }

  /**
   * @param {DocumentId['type']} document
   * @returns {boolean}
   */
  isChild (document) {
    return Object.values(this.#children).flat().includes(document)
  }

  /**
   * Flag the search to be logged
   * @param {boolean} logRun
   * @returns {this}
   */
  logRun (logRun) {
    this.#logRun = logRun
    return this
  }

  /**
   * Unregister a callback from "complete" event
   * @param {Function} callback
   */
  offComplete (callback) {
    this.#eventBus?.$off('complete', callback)
  }

  /**
   * Unregister a callback from "querying" event
   * @param {Function} callback
   */
  offQuerying (callback) {
    this.#eventBus?.$off('querying', callback)
  }

  /**
   * Register a callback for "complete" event
   * @param {Function} callback
   */
  onComplete (callback) {
    this._getOrCreateEventBus().$on('complete', callback)
  }

  /**
   * Register a callback that will be executed once for "complete" event
   * @param {Function} callback
   */
  onceComplete (callback) {
    this._getOrCreateEventBus().$once('complete', callback)
  }

  /**
   * Register a callback for "querying" event
   * @param {Function} callback
   */
  onQuerying (callback) {
    this._getOrCreateEventBus().$on('querying', callback)
  }

  /**
   * Pin a column for further filtering
   * @param {Column} column
   * @param {Operator} [operator]
   * @param {ConditionValueType} [type]
   * @returns {this}
   */
  pinCondition (column, operator, type) {
    this.setFilterSet(this.filterSet.addCondition(column, operator, type))
    return this
  }

  /**
   * @returns {Promise}
   */
  async refreshResults () {
    // If we have no version execute the search immediately
    if (this.#version) {
      this.#debug('Validating version')
      const { data } = await VueInstance.$http().get('/core/v6/search/version')
      if (data.version === this.#version) { return }
      this.#debug('Version mismatch')
    }
    return this.execute(true)
  }

  /**
   * Retrieve the next page
   * @returns {Promise}
   */
  async retrieveNextPage () {
    this.#nextPageQueue = this.#nextPageQueue.finally(async () => {
      if ((this.page + 1) * this.pageSize >= this.resultCount) { return }
      this.#debug('Retrieving next page')
      this.#reactiveData.page++
      const result = this.execute()
      this.#lock = new Promise(resolve => result.finally(() => resolve()))
      return await result
    })
  }

  /**
   * Select or deselect all items
   * @param {boolean} isSelected
   * @returns {Promise}
   */
  async selectAllItems (isSelected) {
    this.#reactiveData.selectedItems.clear()
    this.#reactiveData.allItemsSelected = false
    this.#selectionModeExclusive = false
    if (isSelected && this.resultCount <= MAX_SELECTED_ITEMS && this.resultCount > this.pageSize) {
      const search = new Search(Search.actions.GRID)
        .setDocumentType(this.documentType)
        .setScope(this.scope)
        .setColumnSet([], true)
        .setMainSearch(this)
        .setPageSize(MAX_SELECTED_ITEMS)
        .setActionManager(this.#actionManager)
      await search.execute()
      this.#reactiveData.selectedItems.include(search.items.map(i => i.toCleanedItem()))
    } else {
      if (isSelected) {
        if (this.resultCount <= this.pageSize) {
          this.#reactiveData.selectedItems.include(this.items.map(i => i.toCleanedItem()))
        } else {
          this.#reactiveData.allItemsSelected = isSelected
          this.#selectionModeExclusive = true
        }
      }
    }
    if (this.isShowSelectedItems && !isSelected) {
      await this.showSelectedItems(false).execute()
    }

    if (this.#reactiveData.selectedItems.isEmpty()) {
      this.columns.forEach(c => c.updateSummary())
    }

    this._updateBulkActions()
  }

  /**
   * Set an ActionManager for the search, this is useful to keep actions references
   * @param {ActionManager} actionManager An action manager to keep actions in sync
   * @returns {this}
   */
  setActionManager (actionManager) {
    this.#actionManager = actionManager
    return this
  }

  /**
     * @param {boolean} allowEmptyParamsType
     * @returns {this}
     */
  setAllowEmptyParamsType (allowEmptyParamsType) {
    this.#allowEmptyParamsType = allowEmptyParamsType
    return this
  }

  /**
   * @param {boolean} amtColsOnly
   * @returns {this}
   */
  setAmtColsOnly (amtColsOnly) {
    this.#amtColsOnly = amtColsOnly
    return this
  }

  /**
   * @param {Object} documentType
   * @returns {this}
   */
  setAutoFilterType (documentType) {
    if (documentType) {
      this.#autoFilterType = documentType
    }
    return this
  }

  /**
   * @param {boolean} avgColsOnly
   * @returns {this}
   */
  setAvgColsOnly (avgColsOnly) {
    this.#avgColsOnly = avgColsOnly
    return this
  }

  /**
   * @param {Object} audit
   * @returns {this}
   */
  setAudit (audit) {
    this.#audit = audit
    return this
  }

  /**
   * @param {boolean} boolColsOnly
   * @returns {this}
   */
  setBoolColsOnly (boolColsOnly) {
    this.#boolColsOnly = boolColsOnly
    return this
  }

  /**
   * A scope can be build from the Search class using Search.typeOfScope
   * An empty scope will be global.
   * @param {Scope} scope
   * @example
   * search.setCameleonScope();
   * search.setCameleonScope(Search.typeOfscope.account(this.accountId));
   * @returns {this}
   */
  setCameleonScope (scope) {
    this.#cameleonScope = scope
    return this
  }

  /**
     * @param {boolean} cansSendEmail
     * @returns {this}
     */
  setCanSendEmail (cansSendEmail) {
    this.#canSendEmail = cansSendEmail
    return this
  }

  /**
      * @param {boolean} canSendLetterDematerialized
      * @returns {this}
      */
  setCanSendLetterDematerialized (canSendLetterDematerialized) {
    this.#canSendLetterDematerialized = canSendLetterDematerialized
    return this
  }

  /**
      * @param {boolean} canSendMailevaLetter
      * @returns {this}
      */
  setCanSendMailevaLetter (canSendMailevaLetter) {
    this.#canSendMailevaLetter = canSendMailevaLetter
    return this
  }

  /**
     * @param {boolean} cansSendSms
     * @returns {this}
     */
  setCanSendSms (cansSendSms) {
    this.#canSendSms = cansSendSms
    return this
  }

  /**
   * @param {Object} documentType
   * @returns {this}
   */
  setCategory (documentType) {
    if (documentType) {
      this.#category = documentType
    }
    return this
  }

  /**
   * @param {boolean} cmpColsOnly
   * @returns {this}
   */
  setCmpColsOnly (cmpColsOnly) {
    this.#cmpColsOnly = cmpColsOnly
    return this
  }

  /**
   * Change the current column set
   * @param {string|Array.<string>} columnSetParams  The column set parameters. This can be a ColumnSetId
   * @param {boolean} [doNotApply=false] Do not apply the current changes to main-preset
   * @param {string} [customId]
   * @returns {this}
   */
  setColumnSet (columnSetParams, doNotApply = false, customId) {
    // Shortcut if the search action is not GRID
    if (this.action !== Search.actions.GRID) { return this }
    switch (typeof columnSetParams) {
      case 'undefined':
        this.#reactiveData.internalColumnSet = undefined
        break
      case 'string':
        if (this.mainPreset && columnSetParams !== this.columnSet) {
          this.#reactiveData.internalColumnSet = { 'column-set-id': columnSetParams }
        }
        break
      case 'object':
        if (process.env.NODE_ENV === 'development') {
          /** Dev checks will be removed during webpack's bundling */
          if (!Array.isArray(columnSetParams)) {
            throw new Error(`Column set params must be a column set id or an array of columns id: "${columnSetParams}"`)
          }
        }

        this.#reactiveData.internalColumnSet = { columns: columnSetParams }
        this.#reactiveData.internalCustomColumnSetId = customId
        break
      default:
        throw new Error('Fail to parse column set parameters')
    }

    if (!doNotApply && !_.isEqual(this.mainPreset?.docs[this.documentType]?.columns, this.#reactiveData.internalColumnSet)) {
      this.#debug(
        'Column set changed from "%j" to "%j',
        this.mainPreset?.docs[this.documentType]?.columns,
        this.#reactiveData.internalColumnSet
      )
      this._updateMainPreset('columns', this.#reactiveData.internalColumnSet)
    }

    return this
  }

  /**
   * @param {boolean} dateColsOnly
   * @returns {this}
   */
  setDateColsOnly (dateColsOnly) {
    this.#dateColsOnly = dateColsOnly
    return this
  }

  /**
   * Specify the document type to return in search results
   * @param {DocumentId["type"]} [type]
   * @returns {this}
   */
  setDocumentType (type) {
    if (this.action === Search.actions.GRID) {
      this.#reactiveData.selectedDocumentType = type
      this.#reactiveData.internalColumnSet = undefined
      this.#reactiveData.internalSortSet = undefined
      this.selectAllItems(false)
      this.setFilterSet(new FilterParam(this.mainPreset?.docs?.[type]?.filters), true)
      this.setPins(new DocPin(this.mainPreset?.docs?.[type]?.pins), true)
    }

    if (process.env.NODE_ENV === 'development') {
      if (this.action !== Search.actions.GRID) {
        console.warn(`setDocumentType should only be used with "grid" action, current action is "${this.action}"`)
      }
    }

    this.#debug('Document type changed to "%s"', type)
    return this
  }

  /**
   * @param {Object.<string, IncExc>} filters
   * @returns {this}
   */
  setFilters (filters) {
    if (process.env.NODE_ENV === 'development') {
      /** Dev checks will be removed during webpack's bundling */
      if (this.action !== Search.actions.SUGGEST) {
        throw new Error(`setFilters can only be used with action "${Search.actions.SUGGEST}". Search id ${this.searchId} has action "${this.action}"`)
      }
    }
    this.#filters = filters
    return this
  }

  /**
   * Change the current filterSet
   * @param {FilterParam} filterParam The filter-param to use
   * @param {boolean} [doNotApply=false] Do not apply the current changes to main-preset
   * @returns {this}
   */
  setFilterSet (filterParam, doNotApply = false) {
    if (this.allItemsSelected) {
      this.selectAllItems(false)
    }
    // TODO Try to set filterSet with the given filterParam, without creating a new instance of FilterParam
    const stringifiedFilterParam = JSON.stringify(filterParam)
    const serializedFilterParam = stringifiedFilterParam ? JSON.parse(stringifiedFilterParam) : undefined

    if (!doNotApply && !_.isEqual(serializedFilterParam, this.mainPreset?.docs?.[this.documentType]?.filters)) {
      this.#debug('Filter set changed from "%j" to "%j"', this.mainPreset?.docs?.[this.documentType]?.filters, serializedFilterParam)
      this._updateMainPreset('filters', _.cloneDeep(serializedFilterParam))
    }

    this.#reactiveData.filterSet = new FilterParam(serializedFilterParam)

    return this
  }

  /**
   * @param {boolean} hasEmail
   * @returns {this}
   */
  setHasEmail (hasEmail) {
    this.#hasEmail = hasEmail
    return this
  }

  /**
   * Select or deselect an item
   * @param {Item} item
   * @param {boolean} isSelected
   * @returns {Promise<this>}
   */
  async setItemSelection (item, isSelected) {
    if (!item) { return this }
    const selectedItemDocument = item.toCleanedItem()
    if (this.#selectionModeExclusive) {
      if (!isSelected) {
        this.#reactiveData.selectedItems.exclude([selectedItemDocument])
      } else {
        this.#reactiveData.selectedItems.removeExclude([selectedItemDocument])
      }
    } else {
      if (isSelected) {
        this.#reactiveData.selectedItems.include([selectedItemDocument])
      } else {
        this.#reactiveData.selectedItems.removeInclude([selectedItemDocument])
      }
    }
    if (this.selectedCount === MAX_SELECTED_ITEMS && this.#selectionModeExclusive) {
      const search = new Search(Search.actions.GRID)
        .setDocumentType(this.documentType)
        .setScope(this.scope)
        .setColumnSet([], true)
        .setMainSearch(this)
        .setPageSize(MAX_SELECTED_ITEMS)
        .setActionManager(this.#actionManager)
      search.#searchedIds.exclude(this.#reactiveData.selectedItems.excludeItems)
      await search.execute()
      this.#reactiveData.selectedItems.clear()
      this.#selectionModeExclusive = false
      this.#reactiveData.allItemsSelected = false
      this.#reactiveData.selectedItems.include(search.items.map(i => i.toCleanedItem()))
    }

    if (!this.#reactiveData.selectedItems.isEmpty()) {
      this._getOrCreateGridSelectSearch().execute().then(response => {
        if (response) {
          this.columns.forEach((c, index) => c.updateSummary(response.summaries[index]))
        }
      })
    } else {
      this.columns.forEach(c => c.updateSummary())
    }

    this._updateBulkActions()

    return this
  }

  /**
   * Select or deselect an item
   * @param {boolean} [value] Set the given value, or toggle the value if undefined
   * @returns {this}
   */
  showSelectedItems (value) {
    this.#searchedIds.clear()
    this.setPage(1)
    this.#reactiveData.showSelectedItems = value ?? !this.#reactiveData.showSelectedItems
    if (this.#reactiveData.showSelectedItems) {
      if (this.#reactiveData.selectedItems.size.exclude) {
        this.#searchedIds.exclude(this.#reactiveData.selectedItems.excludeItems)
      }
      if (this.#reactiveData.selectedItems.size.include) {
        this.#searchedIds.include(this.#reactiveData.selectedItems.includeItems)
      }
    }

    return this
  }

  /**
   * Set the keep selection after bulk action flag
   * @param {boolean} keepSelectionAfterAction
   * @returns {this}
   */
  setKeepSelectionAfterAction (keepSelectionAfterAction) {
    this.#reactiveData.keepSelectionAfterAction = keepSelectionAfterAction
    return this
  }

  /**
   * Define the target document for linking when using the LINKING action
   * @param {DocumentId} documentId A document
   * @returns {this}
   */
  setLinkTarget (documentId) {
    this.#linkTarget = documentId
    return this
  }

  /**
   * Specify the link mode
   * @param {DocumentId} target Document to target for linking
   * @param {boolean} link Linking or unlinking
   * @param {boolean} grantUsersOf A document
   * @returns {this}
   */
  setLinkingMode (target, link, grantUsersOf) {
    this.#linkMode = { grantUsersOf, link, target }
    return this
  }

  /**
   * Set a main search that will provide additionnal context for some actions like PIN
   * @param {Search} search A main search that drive the show
   * @param {boolean} ignoreTextMainSearch
   * @returns {this}
   */
  setMainSearch (search, ignoreTextMainSearch = false) {
    if (process.env.NODE_ENV === 'development') {
      /** Dev checks will be removed during webpack's bundling */
      if (search === this) {
        throw new Error('Main search cannot be a self reference')
      }
      if (search.action !== Search.actions.GRID) {
        throw new Error('Main search cannot be a self reference')
      }
    }
    this.#debug('Search %d now depend on search %d as main search', this.searchId, search.searchId)
    this.#mainSearch = search
    this.#ignoreTextMainSearch = ignoreTextMainSearch
    return this
  }

  /**
   * @param {boolean} noColsAging
   * @returns {this}
   */
  setNoColsAging (noColsAging) {
    this.#noColsAging = noColsAging
    return this
  }

  /**
   * @param {boolean} noColsRelation
   * @returns {this}
   */
  setNoColsRelation (noColsRelation) {
    this.#noColsRelation = noColsRelation
    return this
  }

  /**
   * Set the page desired page to return, start at 1
   * @param {number} pageNumber The page number
   * @returns {this}
   */
  setPage (pageNumber) {
    if (process.env.NODE_ENV === 'development') {
      /** Dev checks will be removed during webpack's bundling */
      if (pageNumber < 1) {
        throw new Error(`Page number must be greater than 0, got: "${pageNumber}"`)
      }
    }
    this.#reactiveData.page = pageNumber - 1
    return this
  }

  /**
   * Change the current pins in mainPreset
   * @param {DocPin} pins The pins to use
   * @param {boolean} [doNotApply=false] Do not apply the current changes to main-preset
   * @returns {this}
   */
  setPins (pins, doNotApply = false) {
    // TODO Try to set pins with the given pins, without creating a new instance of DocPin
    const serializedPins = pins?.toJSON()

    if (!doNotApply && !_.isEqual(pins, this.mainPreset?.docs?.[this.documentType]?.pins)) {
      this.#debug('Pins changed from "%j" to "%j"', this.mainPreset?.docs?.[this.documentType]?.pins, serializedPins)
      this._updateMainPreset('pins', serializedPins)
    }

    this.#reactiveData.pins = new DocPin(serializedPins)
    return this
  }

  /**
   * Set the desired page size
   * @param {number} pageSize The page size
   * @returns {this}
   */
  setPageSize (pageSize) {
    if (process.env.NODE_ENV === 'development') {
      /** Dev checks will be removed during webpack's bundling */
      if (pageSize < 1) {
        throw new Error(`Page size must be greater than 1, got: "${pageSize}"`)
      }
    }
    this.#pageSize = pageSize
    return this
  }

  /**
   * Set a document to preview
   * @param {DocumentId} documentId The document id
   * @returns {this}
   */
  setPreviewDocument (documentId) {
    if (process.env.NODE_ENV === 'development') {
      /** Dev checks will be removed during webpack's bundling */
      if (this.action !== Search.actions.GRID) {
        throw new Error('Document preview is only available in grid mode')
      }
    }
    this.#reactiveData.previewDocument = documentId
    return this
  }

  /**
   * A scope can be build from the Search class using Search.scope
   * An empty scope will be global.
   * @param {Scope} scope
   * @example
   * search.setScope();
   * search.setScope(Search.typeOfScope.account(this.accountId));
   * @returns {this}
   */
  setScope (scope = Scope.global()) {
    this.#debug('Scope change to %j', scope)
    this.#reactiveData.scope = scope
    this._setMainPresetPartScopeKey()
    return this
  }

  /**
   * Change the current sort set
   * @param {string|{sortBy: Array.<Object>, sortDesc: Array.<boolean>}} [sortSetParams] The sort set parameters. This can be a SortSetId
   * @param {boolean} [doNotApply=false] Do not apply the current changes to main-preset
   * @param {string} [customId]
   * @returns {this}
   */
  setSortSet (sortSetParams, doNotApply = false, customId) {
    // Shortcut if the search action is not GRID
    if (this.action !== Search.actions.GRID) { return this }
    switch (typeof sortSetParams) {
      case 'undefined':
        this.#reactiveData.internalSortSet = undefined
        break
      case 'string':
        if (this.mainPreset && sortSetParams !== this.sortSet) {
          this.#reactiveData.internalSortSet = { 'sort-set-id': sortSetParams }
        }
        break
      case 'object':
        if (process.env.NODE_ENV === 'development') {
          /** Dev checks will be removed during webpack's bundling */
          if (sortSetParams.sortBy?.length !== sortSetParams.sortDesc?.length) {
            throw new Error(`sortBy and sortDesc must have the same length: "${sortSetParams.sortBy}", "${sortSetParams.sortDesc}"`)
          }
        }

        this.sorts.sortBy = sortSetParams.sortBy.map(col => {
          if (typeof col === 'object') {
            return 'c' + this.columns.findIndex(c => c.id === col.col)
          } else {
            return col
          }
        })

        this.sorts.sortDesc = sortSetParams.sortDesc

        this.#reactiveData.internalSortSet = {
          sorts: _.zip(
            sortSetParams.sortBy.map(col => {
              if (typeof col === 'object') {
                return col
              } else {
                const column = this.#reactiveData.columns[Number(col.slice(1))]
                return {
                  col: column.id,
                  consolidated: column.consolidated ?? undefined,
                  currency: column.currency ?? undefined
                }
              }
            }),
            sortSetParams.sortDesc.map(isDesc => isDesc ? 'd' : 'a')
          )
        }
        this.#reactiveData.internalCustomSortSetId = customId

        break
      default:
        throw new Error('Fail to parse sort set parameters')
    }

    if (!doNotApply && !_.isEqual(this.mainPreset?.docs[this.documentType]?.sorts, this.internalSortSet)) {
      this.#debug(
        'Sort set changed from "%j" to "%j"',
        this.mainPreset?.docs[this.documentType]?.sorts,
        this.internalSortSet
      )
      this._updateMainPreset('sorts', this.internalSortSet)
    }

    if (this.page) {
      this.setPage(1)
    }
    return this
  }

  /**
   * @param {SearchSuggestMode} suggestMode
   * @returns {this}
   */
  setSuggestMode (suggestMode) {
    this.#suggestMode = suggestMode
    return this
  }

  /**
   * @param {boolean} sumColsOnly
   * @returns {this}
   */
  setSumColsOnly (sumColsOnly) {
    this.#sumColsOnly = sumColsOnly
    return this
  }

  /**
   * Set the text to search and fire a new query
   * @param {string} text
   * @returns {this}
   */
  setText (text) {
    this.#debug('text has been changed')
    this.searchText = text
    return this
  }

  /**
   * @param {boolean} workItemTypeIsAccountAction
   * @returns {this}
   */
  setWorkItemTypeIsAccountAction (workItemTypeIsAccountAction) {
    this.#workItemTypeIsAccountAction = workItemTypeIsAccountAction
    return this
  }

  /**
   * Unpin a column
   * @param {Condition} condition
   * @returns {this}
   */
  unpinCondition (condition) {
    this.setFilterSet(this.filterSet.removeCondition(condition))
    return this
  }

  /**
   * Unwatch the main preset
   * @returns {this}
   */
  unwatchMainPreset () {
    this.#debug('Unwatching main preset change')
    if (this.#mainPresetPart) {
      this.#mainPresetPart.active = false
      this.#mainPresetPart = undefined
    }
    this.#unwatchMainPreset?.()
    this.#unwatchMainPreset = undefined
    return this
  }

  /**
   * For the search to keep items in cache for infinite/virtual scrolling while in GRID mode
   * @param {boolean} [useInfiniteScroll=true]
   * @returns {this}
   */
  useInfiniteScroll (useInfiniteScroll = true) {
    this.#useInfiniteScroll = useInfiniteScroll
    return this
  }

  /**
   * Watch the main preset for changes
   */
  watchMainPreset = _.debounce(this._watchMainPreset, 50)

  toJSON () {
    const scope = this.#mainSearch?.scope ?? this.#reactiveData.scope

    const request = {
      action: this.action,
      scope: scope?.type === Scope.GLOBAL || this.action === Search.actions.PIN ? undefined : scope
    }

    const base = {
      allowEmptyParamsType: this.#allowEmptyParamsType,
      audit: this.#audit,
      cameleonScope: this.action === Search.actions.PIN ? scope : this.#cameleonScope,
      canSendEmail: this.#canSendEmail,
      canSendLetterDematerialized: this.#canSendLetterDematerialized,
      canSendMailevaLetter: this.#canSendMailevaLetter,
      canSendSms: this.#canSendSms,
      documentTypes: this.#searchedDocumentTypes,
      groupContributorTypes: this.#groupContributorTypes,
      hasEmail: this.#hasEmail,
      ids: this.#searchedIds,
      linking: this.#linkTarget ? { target: this.#linkTarget } : undefined,
      logRun: this.#logRun,
      workItemTypeIsAccountAction: this.#workItemTypeIsAccountAction
    }

    const text = {
      matchLevel: this.#matchMode,
      text: !_.isEmpty(this.#mainSearch?.searchText) && !this.#ignoreTextMainSearch ? this.#mainSearch?.searchText : this.#reactiveData.searchText ?? ''
    }

    Object.assign(base, text)

    // Add the PageReq to the request if needed by the action
    if ([
      Search.actions.GRID,
      Search.actions.PIN,
      Search.actions.SUGGEST
    ].includes(this.action)) {
      Object.assign(request, {
        page: this._mustQueryFirstPage() ? undefined : this.#reactiveData.page,
        pageSize: this.#pageSize
      })
    }

    switch (this.action) {
      case Search.actions.EXPORT_CSV:
        if (!this.#mainSearch) { throw new Error('Trying to execute EXPORT_CSV action without setting a main search first') }
        Object.assign(
          request,
          base,
          this._getPresets(this.#mainSearch),
          {
            ids: this._getIdsFromMainSearch(),
            selectedType: this.#mainSearch?.documentType
          }
        )
        break
      case Search.actions.GRID:
        if (this.#refreshAllCounts || !this.documentType) {
          // GridDetailReq::AllTypes
          Object.assign(
            request,
            {
              docs: Object.assign(
                {},
                this.mainPreset?.docs,
                this.documentType ? { [this.documentType]: Object.assign({}, this._getPresets(this.#mainSearch), this.#mainSearch ? { columns: this.internalColumnSet, sort: this.internalSortSet } : undefined) } : undefined
              )
            }
          )
        } else {
          // GridDetailReq::OneType
          Object.assign(
            request,
            this._getPresets(this.#mainSearch),
            this.#mainSearch ? { columns: this.internalColumnSet, sort: this.internalSortSet } : undefined
          )
        }
        Object.assign(request, base, { selectedType: this.#reactiveData.selectedDocumentType })
        break
      case Search.actions.GRID_SELECTED: {
        const { columns } = this._getPresets(this.#mainSearch)
        Object.assign(
          request,
          base,
          {
            columns,
            ids: this._getIdsFromMainSearch(),
            selectedType: this.#mainSearch.documentType
          }
        )
        break
      }
      case Search.actions.LINKING:
        Object.assign(
          request,
          base,
          {
            mode: this.#linkMode.link ? 'link' : 'unlink',
            grantUsersOf: this.#linkMode.grantUsersOf,
            target: this.#linkMode.target
          }
        )
        if (this.#mainSearch) {
          Object.assign(
            request,
            {
              documentTypes: new IncExc().include([this.#mainSearch.documentType]),
              filters: this.#mainSearch?.filters,
              ids: this._getIdsFromMainSearch()
            }
          )
        }
        break
      case Search.actions.PIN:
        Object.assign(request, text,
          {
            category: this.#category,
            ids: base.ids,
            target: {
              filters: this.#mainSearch?.filters,
              selectedType: this.#mainSearch?.documentType,
              text: this.#mainSearch?.searchText
            }
          }
        )
        break
      case Search.actions.SUGGEST:
        Object.assign(request, base,
          { autoFilterType: this.#autoFilterType, filters: this.#filters, suggestMode: this.#suggestMode },
          {
            amtColsOnly: this.#amtColsOnly,
            avgColsOnly: this.#avgColsOnly,
            boolColsOnly: this.#boolColsOnly,
            cmpColsOnly: this.#cmpColsOnly,
            dateColsOnly: this.#dateColsOnly,
            noColsAging: this.#noColsAging,
            noColsRelation: this.#noColsRelation,
            sumColsOnly: this.#sumColsOnly
          }
        )
        break
      case Search.actions.DELETE:
      case Search.actions.DOWNLOAD_LETTERS:
      case Search.actions.END_ESCALATION_PROTOCOLS:
      case Search.actions.EXECUTE_PROCESSES:
      case Search.actions.REORDER:
      case Search.actions.SEND_AR24_MESSAGE:
      case Search.actions.SEND_MAILEVA_LETTER:
      case Search.actions.SET_ACCOUNT_CONTACT_GROUPS:
      case Search.actions.SET_ACCOUNT_CONTACT_PROPERTIES:
      case Search.actions.SET_ACCOUNT_PROPERTIES:
      case Search.actions.SET_ALLOCATION_ANNOUNCEMENT_PROPERTIES:
      case Search.actions.SET_COLLABORATION_PROPERTIES:
      case Search.actions.SET_DISPUTE_PROPERTIES:
      case Search.actions.SET_INVOICE_PROPERTIES:
      case Search.actions.SET_LABELS_LINK:
      case Search.actions.SET_PROMISE_PROPERTIES:
      case Search.actions.SET_REMINDER_STATUS:
      case Search.actions.SET_TOPIC_PROPERTIES:
      case Search.actions.SET_USER_PROPERTIES:
      case Search.actions.SET_WORK_ITEMS:
      case Search.actions.SYNC_AR24_MESSAGE:
      case Search.actions.SYNC_MAILEVA_LETTER:
        Object.assign(request, base)
        if (this.#mainSearch) {
          Object.assign(request,
            {
              documentTypes: new IncExc().include([this.#mainSearch.documentType]),
              filters: this.#mainSearch?.filters,
              ids: this._getIdsFromMainSearch()
            })
        }
        break
      default:
        throw new Error(`Action "${this.action}" is not handled in toJSON`)
    }

    if (process.env.NODE_ENV === 'development') {
      /** Dev checks will be removed during webpack's bundling */
      Object.assign(request, { _DEBUG_searchId: this.searchId })
    }

    return request
  }

  /** Getters and setters for direct access to observable properties */

  /**
   * @returns {SearchAction}
   */
  get action () {
    return this.#action
  }

  get actionManager () {
    return this.#actionManager
  }

  /**
   * @returns {boolean}
   */
  get allItemsSelected () {
    return this.#reactiveData.allItemsSelected
  }

  /**
   * @returns {(boolean|undefined)}
   */
  get allowEmptyParamsType () {
    return this.allowEmptyParamsType
  }

  /**
   * @returns {boolean}
   */
  get amtColsOnly () {
    return this.#amtColsOnly
  }

  /**
   * @returns {Object}
   */
  get audit () {
    return this.#audit
  }

  /**
   * @returns {boolean}
   */
  get avgColsOnly () {
    return this.#avgColsOnly
  }

  /**
 * @returns {boolean}
 */
  get boolColsOnly () {
    return this.#boolColsOnly
  }

  /**
   * @returns {Array.<Action>}
   */
  get bulkActions () {
    return this.#reactiveData.bulkActions
  }

  /**
   * @returns {boolean}
   */
  get canSelectItems () {
    return this.#reactiveData.canSelectItems
  }

  /**
   * @returns {Object.<DocumentId['type'], Array.<DocumentId['type']>>}
   */
  get children () {
    return this.#children
  }

  /**
   * @returns {boolean}
   */
  get cmpColsOnly () {
    return this.#cmpColsOnly
  }

  /**
   * @returns {Object}
   */
  get counts () {
    return this.#reactiveData.counts
  }

  /**
   * Columns used by v-data-table
   * @returns {Array.<Column>}
   */
  get columns () {
    return this.#reactiveData.columns
  }

  /**
   * @returns {string}
   */
  get columnSet () {
    return this.#reactiveData.internalColumnSet
      ? this.#reactiveData.internalColumnSet?.['column-set-id']
      : this.mainPreset?.docs?.[this.documentType]?.columns?.['column-set-id']
  }

  /**
 * @returns {string}
 */
  get customColumnSetId () {
    return this.#reactiveData.internalCustomColumnSetId
  }

  /**
  * @returns {string}
  */
  get customSortSetId () {
    return this.#reactiveData.internalCustomSortSetId
  }

  /**
   * @returns {boolean}
   */
  get dateColsOnly () {
    return this.#dateColsOnly
  }

  /**
   * @returns {(string|undefined)}
   */
  get documentType () {
    return this.#reactiveData.selectedDocumentType
  }

  /**
   * @returns {Object}
   */
  get filters () {
    return this.mainPreset?.docs?.[this.documentType]?.filters ?? this.#filters
  }

  /**
   * @returns {FilterParam}
   */
  get filterSet () {
    return this.#reactiveData.filterSet
  }

  /**
   * @returns {*}
   */
  get internalColumnSet () {
    return this.#reactiveData.internalColumnSet
  }

  /**
   * @returns {*}
   */
  get internalSortSet () {
    return this.#reactiveData.internalSortSet
  }

  get groupContributorTypes () {
    return this.#groupContributorTypes
  }

  /**
   * @returns {(boolean|undefined)}
   */
  get hasEmail () {
    return this.hasEmail
  }

  /**
   * @returns {Array.<Item>}
   */
  get items () {
    return this.#reactiveData.items ?? []
  }

  /**
   * Return true if the current items are sorted by relevance
   * @returns {boolean}
   */
  get isSortedByRelevance () {
    return !this.sortSet &&
      !this.#reactiveData.internalSortSet?.sorts?.length &&
      !this.mainPreset?.docs[this.documentType]?.sorts?.sorts?.length
  }

  get keepSelectionAfterAction () {
    return this.#reactiveData.keepSelectionAfterAction
  }

  get linkTarget () {
    return this.#linkTarget
  }

  get loading () {
    return this.#reactiveData.querying
  }

  /**
  * @returns {boolean}
  */
  get noColsAging () {
    return this.#noColsAging
  }

  /**
   * @returns {boolean}
   */
  get noColsRelation () {
    return this.#noColsRelation
  }

  get mainPreset () {
    return this.#reactiveData.mainPreset
  }

  get page () {
    return this.#reactiveData.page
  }

  get pageCount () {
    return this.#reactiveData.pageCount
  }

  get pageSize () {
    return this.#pageSize
  }

  /**
   * @returns {DocPin}
   */
  get pins () {
    return this.#reactiveData.pins
  }

  get previewDocument () {
    return this.#reactiveData.previewDocument
  }

  /**
   * @returns {number}
   */
  get resultCount () {
    return this.#reactiveData.counts[this.documentType]?.[0] ?? 0
  }

  get searchedDocumentTypes () {
    return this.#searchedDocumentTypes
  }

  get searchedIds () {
    return this.#searchedIds
  }

  get searchId () {
    return this.#searchId
  }

  get scope () {
    return this.#reactiveData.scope
  }

  /**
   * @returns {string}
   */
  get searchText () {
    return this.#reactiveData.searchText
  }

  /**
   * @param {string} searchText The text to search
   */
  set searchText (searchText) {
    searchText ??= ''
    if (searchText !== this.#reactiveData.searchText) {
      this.#reactiveData.searchText = searchText.slice(0, 2000) // Cap the search text at 2000 characters
      if (this.#searchOnType) {
        this.execute(true)
      }
    }
  }

  get selectedCount () {
    return this.selectionModeExclusive ? (this.resultCount - this.selectedItems.size.exclude) : this.selectedItems.size.include
  }

  get selectedItems () {
    return this.#reactiveData.selectedItems
  }

  /**
   * @returns {boolean}
   */
  get selectionModeExclusive () {
    return this.#selectionModeExclusive
  }

  /**
   * @returns {boolean}
   */
  get isShowSelectedItems () {
    return this.#reactiveData.showSelectedItems
  }

  /**
   * Sort order used by v-data-table
   */
  get sorts () {
    return this.#reactiveData.sorts
  }

  /**
   * @returns {string}
   */
  get sortSet () {
    return this.#reactiveData.internalSortSet
      ? this.#reactiveData.internalSortSet?.['sort-set-id']
      : this.mainPreset?.docs?.[this.documentType]?.sorts?.['sort-set-id']
  }

  /**
   * @returns {boolean}
   */
  get sumColsOnly () {
    return this.#sumColsOnly
  }

  /**
   * @returns {Object}
   */
  get visiblePins () {
    return this.#reactiveData?.visiblePins
  }

  /**
   * @returns {(boolean|undefined)}
   */
  get workItemTypeIsAccountAction () {
    return this.#workItemTypeIsAccountAction
  }

  /**
   * Return a map of available actions for a given item
   * @param {RawItem} item
   * @returns {Map<string, Action>}
   */
  static getActions (item) {
    return new ItemList(
      [item],
      new ActionManager(new Search(Search.actions.GRID).setDocumentType(item.type), false)
    ).toArray()[0].actions
  }

  /**
   * @type {typeof Scope}
   */
  static get typeOfScope () {
    return Scope
  }

  /** Static enum */
  /**
   * @enum {SearchAction}
   */
  static actions = {
    /** @type {SearchAction} */
    DELETE: 'delete',
    /** @type {SearchAction} */
    DOWNLOAD_LETTERS: 'download-letters',
    /** @type {SearchAction} */
    END_ESCALATION_PROTOCOLS: 'end-escalation-protocols',
    /** @type {SearchAction} */
    EXECUTE_PROCESSES: 'execute-processes',
    /** @type {SearchAction} */
    EXPORT_CSV: 'export-csv',
    /** @type {SearchAction} */
    LINKING: 'linking',
    /** @type {SearchAction} */
    GRID: 'grid',
    /** @type {SearchAction} */
    GRID_SELECTED: 'grid-selected',
    /** @type {SearchAction} */
    PIN: 'pin',
    /** @type {SearchAction} */
    REORDER: 'reorder',
    /** @type {SearchAction} */
    SEND_AR24_MESSAGE: 'send-ar24-message',
    /** @type {SearchAction} */
    SET_LABELS_LINK: 'set-labels-link',
    /** @type {SearchAction} */
    SEND_MAILEVA_LETTER: 'send-maileva-letter',
    /** @type {SearchAction} */
    SET_ACCOUNT_CONTACT_GROUPS: 'set-account-contact-groups',
    /** @type {SearchAction} */
    SET_ACCOUNT_CONTACT_PROPERTIES: 'set-account-contact-properties',
    /** @type {SearchAction} */
    SET_ACCOUNT_PROPERTIES: 'set-account-properties',
    /** @type {SearchAction} */
    SET_ALLOCATION_ANNOUNCEMENT_PROPERTIES: 'set-allocation-announcement-properties',
    /** @type {SearchAction} */
    SET_COLLABORATION_PROPERTIES: 'set-collaboration-properties',
    /** @type {SearchAction} */
    SET_REMINDER_STATUS: 'set-reminder-status',
    /** @type {SearchAction} */
    SET_DISPUTE_PROPERTIES: 'set-dispute-properties',
    /** @type {SearchAction} */
    SET_INVOICE_PROPERTIES: 'set-invoice-properties',
    /** @type {SearchAction} */
    SET_PROMISE_PROPERTIES: 'set-promise-properties',
    /** @type {SearchAction} */
    SET_TOPIC_PROPERTIES: 'set-topic-properties',
    /** @type {SearchAction} */
    SET_USER_PROPERTIES: 'set-user-properties',
    /** @type {SearchAction} */
    SET_WORK_ITEMS: 'set-work-items',
    /** @type {SearchAction} */
    SUGGEST: 'suggest',
    /** @type {SearchAction} */
    SYNC_AR24_MESSAGE: 'sync-ar24-message',
    /** @type {SearchAction} */
    SYNC_MAILEVA_LETTER: 'sync-maileva-letter'
  }

  /**
   * @enum {SearchMatchMode}
   */
  static matchMode = {
    /** @type {SearchMatchMode} */
    DISTANCE: undefined, // 'distance' is the defaulted value, we'll use undefined to reduce the payload
    /** @type {SearchMatchMode} */
    CONTAINS: 'contains',
    /** @type {SearchMatchMode} */
    EXACT: 'exact'
  }

  /**
 * @enum {SearchSuggestMode}
 */
  static suggestMode = {
    /** @type {SearchSuggestMode} */
    PICKER: undefined,
    /** @type {SearchSuggestMode} */
    GLOBAL_SEARCH: 'global-search'
  }
}

// @ts-ignore
if (window.Cypress) {
  // @ts-ignore
  window.Search = Search
}
