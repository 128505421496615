<template>
  <main-document
    :tabs="tabs"
    v-on="$listeners"
  />
</template>

<script>
import tabController from '@/tabController'

export default {
  components: {
    MainDocument: () => import('@/components/main-document')
  },
  computed: {
    tabs () {
      return [tabController.create(tabController.accountContactsTab(), { documentId: this.documentId })]
    }
  },
  props: {
    documentId: String
  }
}
</script>
