<template>
  <resize-observer
    class="overflow-y-hidden"
    style="width: 100%"
    @resize="evaluateDisplayMode"
  >
    <cache-presenter
      :cache="cache"
      :is-new="documentIsNew"
      class="d-flex flex-column fill-height flex-grow-1"
    >
      <document-toolbar
        :tab="tab"
        :save="saveDocument"
        :revert="refreshDocument"
        :files.sync="document.attachments"
        no-delete-toast
        show-file-manager
        v-on="$listeners"
        :show-delete="(userIsMasterAdmin || $store.getters.currentUserHasPermission('NoteCanDelete')) && statusId === 1 && tab.isPristine"
        :delete-document="deleteDocument"
        :labels="document.labels"
        :show-labels="!documentIsNew"
      >
        <div class="mr-1">
          <span
            v-if="documentIsNew"
            class="ml-1 secondary--text font-weight-bold"
          >{{status}}</span>
          <v-select
            v-else
            class="mx-1"
            solo
            hide-details
            dense
            :items="statuses"
            item-text="name"
            item-value="id"
            v-model="statusId"
            :menu-props="{closeOnContentClick: true, bottom: true, offsetY: true}"
          />
        </div>
      </document-toolbar>
      <document-in-tab>
        <v-form
          class="d-flex flex-column fill-height"
          v-model="form"
          ref="form"
        >
          <template v-if="fullPageDisplay">
            <v-card class="card-width mb-1 d-flex flex-column">
              <v-card-title>
                {{$t('t.Topic')}}<span v-if="document.id"> #{{document.id}}</span>
                <div>
                  <v-select
                    class="mx-1 ml-12"
                    solo
                    hide-details
                    :items="orderActions"
                    item-text="name"
                    item-value="value"
                    v-model="orderOldFirst"
                    :menu-props="{closeOnContentClick: true, bottom: true, offsetY: true}"
                  />
                </div>
                <v-spacer />
                <v-btn
                  v-if="!documentIsNew"
                  @click="openInSidePanel"
                  icon
                  small
                  class="background-plain ml-2"
                  elevation=0
                >
                  <icon-tooltip
                    icon-name="i.OpenInSidePanel"
                    :tooltip-text="$t('t.OpenInSidePanel')"
                  />
                </v-btn>
              </v-card-title>
              <v-card-text class="d-flex overflow-y-hidden">
                <div class="d-flex flex-grow-1 justify-space-around">
                  <topic-content
                    :document="document"
                    :document-is-new="documentIsNew"
                    :order-old-first="orderOldFirst"
                    :subject.sync="document.subject"
                  />
                  <div class="d-flex properties">
                    <v-card class="d-flex">
                      <v-card-text class="overflow-y-auto">
                        <topic-properties
                          :topic.sync="document"
                          @update-links="refreshCache()"
                        />
                      </v-card-text>
                    </v-card>
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </template>
          <template v-else>
            <v-tabs
              background-color="transparent"
              centered
              class="mb-3 tabs"
              color="primary"
              grow
              slider-color="primary"
              v-model="tabIndex"
            >
              <v-tab>
                {{$t('t.Topic')}}
              </v-tab>
              <v-tab>
                {{$t('t.Properties')}}
              </v-tab>
            </v-tabs>
            <v-tabs-items
              v-model="tabIndex"
              class="tabs-wrapper"
            >
              <v-tab-item class="pt-1 background-translucid overflow-y-hidden flex">
                <topic-content
                  :document="document"
                  :document-is-new="documentIsNew"
                  :order-old-first.sync="orderOldFirst"
                  :subject.sync="document.subject"
                  dense
                />
              </v-tab-item>
              <v-tab-item class="overflow-y-auto">
                <topic-properties
                  :topic.sync="document"
                  @update-links="refreshCache()"
                />
              </v-tab-item>
            </v-tabs-items>
          </template>
        </v-form>
      </document-in-tab>
    </cache-presenter>
  </resize-observer>
</template>

<script>

import Search, { GlobalSearch } from '@/pages/search/controllers'
import DocumentEditor from '@/mixins/document-editor'
import sidePanelController from '@/sidePanelController'
import { getDoc } from '@/wasm/pkg'

export default {
  mixins: [DocumentEditor],
  components: {
    CachePresenter: () => import('@/components/cache-presenter'),
    DocumentInTab: () => import('@/components/document-in-tab'),
    DocumentToolbar: () => import('@/components/document-toolbar'),
    ResizeObserver: () => import('@/components/resize-observer'),
    TopicContent: () => import('./topic-content'),
    TopicProperties: () => import('./topic-properties'),
    IconTooltip: () => import('@/components/icon-tooltip')
  },
  data () {
    return {
      fullPageDisplay: true,
      link: null,
      orderOldFirst: true,
      tabIndex: 0,
      search: null
    }
  },
  async created () {
    Object.assign(this.tab, { cancel: this.cancel })
  },
  activated () {
    this.createSearch()
  },
  deactivated () {
    this.search = undefined
  },
  mounted () {
    this.createSearch()
  },
  computed: {
    userIsMasterAdmin () {
      return this.$store.getters.isMasterAdmin()
    },
    actionItem () {
      return {
        id: this.id,
        type: this.tab.documentType
      }
    },
    cacheType () {
      return DocumentEditor.CacheType.TopicDetail
    },
    form: {
      get () {
        return false
      },
      set (v) {
        this.$emit('document-can-save', { tab: this.tab, value: Boolean(v) })
      }
    },
    id () {
      return Number(this.tab.documentId) || 0
    },
    orderActions () {
      return [
        {
          value: false,
          name: this.$t('t.NewestFirst')
        },
        {
          value: true,
          name: this.$t('t.OldestFirst')
        }
      ]
    },
    status () {
      return this.document.closedAt ? this.$t('t.Closed') : this.$t('t.Open')
    },
    statusId: {
      get () {
        if (this.documentIsNew || !this.document.closedAt) {
          return 0
        } else {
          return 1
        }
      },
      set (value) {
        if (value === 1) {
          this.document.closedAt = this.moment()
        } else {
          this.document.closedAt = null
        }
      }
    },
    statuses () {
      const statuses = []
      statuses.push({
        id: 0,
        name: this.$t('t.Open')
      })
      statuses.push({
        id: 1,
        name: this.$t('t.Closed')
      })
      return statuses
    },
    subjectMaxLength () {
      return 255
    }
  },
  methods: {
    createSearch () {
      this.search = new Search(Search.actions.LINKING)
    },
    async refreshDocumentHook () {
      if (this.documentIsNew) {
        const defaultTopic = await this.$http().get('/core/v6/topics/defaults')
        this.document = Object.assign({}, this.document, {
          subject: '',
          post: { text: '' },
          description: '',
          assigned: [],
          attachments: [],
          labels: [],
          visible: defaultTopic?.data?.visible || [],
          links: []
        })

        if (Array.isArray(this.tab.links)) {
          let subject

          const link = this.tab.links[0].id
          if (link.type !== 'topics') {
            const cacheTypeNumber = this.$cacheTypeFromString(link.type)
            const doc = await getDoc(cacheTypeNumber, link.id)
            subject = this.formatSubject(doc, cacheTypeNumber)
          }

          this.document = Object.assign({}, this.document, { links: this.tab.links, subject })
        }
      } else {
        // Prevent doing anything if the cache isn't loaded
        if (!this.document?.id) { return }
        this.$emit('document-title-change', { tab: this.tab, value: `${this.document.subject}` })
      }

      await this.$waitFor(() => this.$refs.form)
        .then(form => setTimeout(() => {
          form?.validate()
          this.$emit('document-ready', this.tab)
        }, 500))
        .catch(e => {
          // Do not throw if the cache is falsy, that mean the user as already close the document
          if (this.cache) {
            throw e
          }
        })
    },
    evaluateDisplayMode (rect) {
      this.fullPageDisplay = rect.width > 1300
    },
    formatSubject (doc, cacheTypeNumber) {
      if (doc) {
        let subject = this.$t(this.$cacheTypeToTranslatedTag(cacheTypeNumber))
        if (doc.number) {
          subject += ` #${doc.number}`
        } else if (doc.subject) {
          subject += ` - ${doc.subject}`
        }
        return subject
      } else return ''
    },
    openInSidePanel () {
      sidePanelController.displayDocument(this.document.id, 'topics')
    },
    cancel () {
      this.$removeFromKeepAliveCache()
      this.$destroy()
    },
    topicToJSON () {
      const formatedAttachments = this.document.attachments?.map(a => a.id)
      return {
        subject: this.document.subject,
        isClosed: this.statusId === 1,
        description: this.document.description,
        attachments: formatedAttachments,
        assigned: this.document.assigned,
        visible: this.document.visible
      }
    },
    async deleteDocument () {
      Search.getActions(this.actionItem)
        .get('delete-topic')
        .executeQuick(
          this.actionItem,
          () => {
            if (this.isInSidePanel) {
              this.$emit('close-document')
              if (GlobalSearch.activeSearch?.action === 'grid') {
                GlobalSearch.activeSearch.refreshResults()
              }
            } else {
              this.$router.replace('/').catch(e => {
                if (e.name !== 'NavigationDuplicated') {
                  throw e
                }
              })
            }
          }
        )
    },
    async save () {
      if (this.documentIsNew) {
        const response = await this.$http().post('/core/v6/topics', this.topicToJSON())
        const id = response?.data?.id
        if (this.document.links.length) {
          await this.search
            .setLinkingMode({ id, type: 'topics' }, true, this.document.links[0].grantUsersOf)
            .chain(s => s.searchedIds.clear().include(this.document.links.map(l => l.id)))
            .execute()
        }
        if (this.$route.name !== 'account') {
          this.$nextTick(() => this.$router.replace({
            path: `/topics/${id}`
          }))
        } else {
          Object.assign(this.tab, { documentId: id })
        }
        return response
      } else {
        return this.$http().put(`/core/v6/topics/${this.document.id}`, this.topicToJSON())
      }
    }
  },
  props: {
    tab: Object
  }
}
</script>

<style lang="stylus" scoped>
.topic-infos
  display flex
  overflow hidden
  flex-grow 1
  flex-wrap wrap
  width 100%
  height 100%

.card-width
  width calc(100% - 12px)
  height calc(100% - 3px)

.flex
  display flex

.properties
  width 30%

.tabs-wrapper
  flex 100% 0 1
  height 100%
</style>
